.container-search-survey {
  margin-bottom: 2rem;
}

.input-container-search-survey {
    display: flex;
    width: 68rem;
    flex-wrap: wrap;
}

.label {
  margin-top: 1rem;
  display: block;
}

.search-survey-header {
  font-weight: bold;
  font-size: 2rem;
}

.search-survey-input {
    border: none;
    color: #333;
    font-size: 1em;
    font-family: inherit;
    outline: none;
    height: 3rem;
    width: 18rem;
    padding-left: 1rem;
    border-radius: 0.4rem;
    box-shadow: 0px 1px 2px 0px rgba(150, 150, 150, 0.7);
}

.search-survey-submit-container {
  margin-top: 2rem;
}
