.forgot {
  color: black;
  margin-top: 4vh;
}

.logo-login {
  margin-bottom: 20px;
  width: 300px;
}

.pageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #fff;
  padding: 60px;
  min-width: 300px;
  max-width: 600px;
  min-height: 400px;
  margin: 30px auto;
}

.error {
  margin: 10px 0;
  display: block;
  font-size: 15px;
  font-style: italic;
  color: #f00;
}

.modalInput + .error {
  margin: -10px 0 10px;
}

.forgotPassword {
  font-size: 14px;
  color: gray;
  color: $darkBlue;
  margin-top: 30px;
  border: none;
}
