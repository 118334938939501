$break-xs: 480px;
$break-s: 768px;
$break-m: 992px;
$break-lg: 1200px;
.divider {
  display: none;
  @media screen and (min-width: $break-xs) and (max-width: $break-lg) {
    display: block;
  }
}

.pageName {
  font-weight: bold;
  font-size: 18px;
}

.auth {
  font-size: 12px;
  color: gray;
}

.templateLink {
  white-space: nowrap;
  a {
    color: $darkBlue;
    font-weight: 600;
  }
  .fake-link{
    color: $darkBlue;
    font-weight: 600;
    cursor:pointer;
    &:hover{
      text-decoration: underline;
    }
  }
}

.colTitle {
  font-weight: 700;
  font-size: 20px;
}
