.quiz-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  cursor: pointer;
  background-color: #fff;
  min-height: 64px;
  border-radius: 12px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  font-weight: bold;
  color: $contentGrey;
  padding: 10px 0;
  margin: 8px 0;

  &:active {
    box-shadow: none;
  }

  &:hover {
    color: $white;
    background-color: $darkBlue;

    .keyboard-indicator-styles {
      background-color: $midBlue;
      border: none;
      box-shadow: 0px 2px 0px 0px $darkBlue;
    }
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.3rem rgba($lightBlue, 0.25);
  }

  &.fat {
    width: 49.6%;
    height: 150px;
    justify-content: center;
    align-items: center;
    margin: 10px;

    .qb-text {
      margin: 0 auto;
      display: block;
      text-align: center;
      font-size: 30px;
    }
    .keyboard-indicator-styles {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }

    &.has-audio {
      margin-bottom: 2.5rem;
    }
  }

  &.skinny {
    border-radius: 5px;
    justify-content: space-between;
    flex-flow: row;
    flex-direction: row;
    width: 100%;

    .qb-text {
      font-size: 13pt;
      font-weight: bold;
      margin: 0 0 0 37px;
      display: inline-block;
      float: left;
    }

    .keyboard-indicator-styles {
      margin: 0 27px 0 0;
      display: inline-flex;
      float: right;
    }
  }
}

.clicked {
  background-color: $midBlue;
  color: $white;

  .keyboard-indicator-styles {
    background-color: $lightBlue;
    border: none;
    box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  }
}

.keyboard-indicator-styles {
  background-color: white;
  border: solid 1px rgba(0, 0, 0, 0.15);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  font-size: 10pt;
}
