$maxLayoutWidth: 1400px;
$headerFooterHeight: 360px;

.tm-layout {
  > .container,
  header > .container {
    max-width: $maxLayoutWidth;
    min-height: calc(100vh - #{$headerFooterHeight});
  }
}

// Adjust survey contain to take up available height
.survey-container {
  main {
    min-height: calc(100vh - #{$headerFooterHeight});
  }
}
