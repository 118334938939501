.debug-content {
  border-bottom: 1px dashed $red;
  margin-bottom: -1px;

  &:hover {
    border-bottom: none;
    background-color: $lightRed;
    color: $white;
    cursor: help;
    position: relative;

    &:before {
      content: 'data-key:'attr(data-key)' | data-audienceFallback:'attr(data-audience-fallback)' | data-defaultKey:'attr(data-default-key)' | data-id:'attr(data-id)' | data-type:'attr(data-type)' | data-lang:'attr(data-lang)' | data-audience:'attr(data-audience);
      position: absolute;
      bottom: 100%;
      padding: 5px;
      text-transform: initial;
      line-height: 1;
      font-size: 14px;
      font-weight: normal;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      background-color: $lightRed;
      white-space: nowrap;
    }
  }
}
