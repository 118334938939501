.App {
  text-align: center;
}

.appLogo {
  animation: appLogoSpin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.appHeader {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.appLink {
  color: #61dafb;
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
