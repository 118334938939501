.sub-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1.125rem;
  min-height: 50px;

  button:last-child {
    margin-right: 0;
  }
}
