.container-participant-confirmation {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-left: 20%;
  margin-top: 4rem;
}

.gradeLine {
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.head {
  font-weight: bold;
  font-size: 18pt;
  margin-top: 0;
  margin-bottom: 0;
}

.heading {
  text-align: left;
  width: 100%;
}

.studentInformation {
  font-size: 20px;
  line-height: 15px;
  text-align: left;
  margin-top: 40px;
  width: 100%;

  div {
    span &:first-of-type {
      font-weight: bold;
    }
  }
}

