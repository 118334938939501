.container-radio-btn {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  display: row;
  align-items: center;
  input {
    margin-right: 12px;
  }

  &.has-audio {
    position: relative;
    margin-bottom: 2.5rem;
  }
}
.description {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 2vw;
  text-align: left;
}
