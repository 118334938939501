.language-picker {
  display: block;
  font-size: 14px;
  font-family: sans-serif;
  color: #444;
  line-height: 1.3;
  padding: 5px 40px 6px 12px;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: 2em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212.871%22%20height%3D%228.352%22%20viewBox%3D%220%200%2012.871%208.352%22%3E%3Cpath%20d%3D%22M8.435%2C10.352%2C2%2C3.917%2C3.917%2C2%2C8.435%2C6.518%2C12.954%2C2l1.917%2C1.917Z%22%20transform%3D%22translate%28-2%20-2%29%22%20fill%3D%22%232d809f%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient( 90deg, rgba(0,0,0,0) calc(80% - 1px), rgb(150, 150, 150) calc(80%), rgba(0,0,0,0) calc(80% + 1px) );
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, right 30px;
  background-size: 9px auto, 100%;

  &:hover {
    border-color: #888;
  }

  &:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
  }

  &:disabled,
  &[aria-disabled=true] {
    background-color: #eee;
  }

  &::-ms-expand {
    display: none;
  }

  option {
    font-weight: normal;
  }

}
