.alert {
  margin-top: 1rem;
  color: white !important;
  background-color: $lightBlue !important;
  height: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 1rem !important;
}

.alertHeader {
  font-weight: bold;
  font-size: 1.2rem;
}

.breadcrumbButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.breadcrumbLink {
  color: black;
  text-decoration: underline;
}

.btnContainer {
  display: flex;
  margin-top: 1rem;
}

.content {
  margin: 0 10px;
}

.modalContent {
  padding: 2rem;
}

.modalBtn {
  width: 12rem;
  border-radius: 2rem !important;
}

.modalInput {
  width: 100%;
  background-color: $inputFill;
  padding: 15px;
  border:none;
  border-radius: 5px;
  margin: 4px 0 20px;
}

.table {
  [data-key=icon],
  [data-key=star] {
    width: 1px;
    white-space: nowrap;
  }
}

.tableHeader {
   background-color: $darkBlue;
   color: $white;
   font-size: 0.8rem;
}

Table {
  background-color: white;
}

.tmHeader {
  font-size: 0.8rem;
  color: grey;
}
