/**
Author bcorbin@fusionalliance.com
A generic button component that focsues on using Bootstrap classes.
**/

.tm-button {
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

  /** Create a Green Buttonb **/
  &.btn-green {
    background-color: $darkGreen;
    border: none;
    color: #fff;
    &:hover {
      background-color: darken($darkGreen, 5%);
    }
  }

  /** Made Large Button Large **/
  &.btn-lg {
    height: 70px;
    font-size: 17px;
    &.btn-round {
      border-radius: 35px;
    }
  }

  /** Position an Icon on the Right **/
  .btn-icon-right {
    transition: all 0.75s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 40px;
    top: 0;
    bottom: 0;
  }

  /** Icon Left side **/
  .btn-icon-left {
    transition: all 0.75s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 40px;
    top: 0;
    bottom: 0;
  }

  &:hover {
    .btn-icon-right {
      transition: all 0.75s ease-in-out;
      right: 20px;
    }
    .btn-icon-left {
      transition: all 0.75s ease-in-out;
      left: 20px;
    }
  }

  &.w-300 {
    width: 300px;
  }
}
