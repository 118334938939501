.container-search-reports {
  margin-bottom: 2rem;
}

.input-container-search-reports {
  display: flex;
  width: 70rem;
  flex-wrap: wrap;
}

.radio-container-search-reports {
  margin-right: 2rem !important;
}

.radio-search-reports {
  margin-top: 1rem;
  div {
    margin-right: 1rem;
    align-items: baseline;
    input {
      margin-right: 1rem;
    }
  }
}
