// Play/Pause button for Read To Me functionality
.audio-button {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  padding: 3px 12px 4px;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid $lightBlue;
  border-radius: 2em;
  background-color: transparent;

  &:hover {
    background-color: #fff;
  }

  &:focus {
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
  }

  svg {
    fill: $lightBlue;
    vertical-align: bottom;
  }

  span {
    vertical-align: bottom;
    color: $lightBlue;
  }

  &.has-label {
    background-image: linear-gradient(to right, rgba(51, 147, 185, 1) 0, rgba(51, 147, 185, 1) 40px, rgba(0, 0, 0, 0) 40px);

    svg {
      margin-right: 16px;
      fill: $white;
    }
  }

  &.small {
    height: 1.25em;
    line-height: 0;
    padding: 1px 5px;

    svg {
      height: 0.85em;
    }
  }
}

.quiz-button .audio-button {
  position: absolute;
}
.quiz-button.fat .audio-button {
  left: 0;
  bottom: -40px;
}
.quiz-button.skinny .audio-button {
  right: -70px;
}

.container-radio-btn .audio-button {
  position: absolute;
  top: 110%;
  left: 26px;
}
