
.subhead {
  font-size: 14px;
}

.container-demo-questions {
  animation: fadeIn 0.3s ease-out 1 forwards;
  max-width: 600px;
  width: 80%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto;
  position: relative;
  left: 0;
  min-height:500px;
  opacity: 1;

  .answerContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .radioGroup {
    label {
      width: 50%;
    }
  }

  .head {
    font-weight: bold;
    font-size: 18pt;
    margin-top: 0;
    margin-bottom: 0;
  }
  .heading {
    text-align: left;
    width: 100%;
  }
  .leftColumn,
  .rightColumn {
    width: 50%;
  }
}
