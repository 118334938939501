$darkSea: #00657e;

@media print {
  // Force a white background when in print mode..
  // Note this is global.
  body {
    background-color: #fff;
    -webkit-print-color-adjust: exact;
  }
}

@page {
  size: auto; /* auto is the initial value */
  margin: 0mm; /* this affects the margin in the printer settings */
}

/**
 * group specific
*/
.tm-report-group {
  background-color: #fff;

  header {
    height: auto;
    position: relative;

    .summary {
      text-align: right;
    }

    .group-header {
      h2 {
        font-size: 1.7rem;
      }
      h3 {
        font-size: 1.4rem;
      }
      .locations {
        font-size: 0.8rem;
      }
    }

    .branding {
      img {
        height: 50px;
        left: 30px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  } // end header

  .page-footer {
    min-width: 100%;
    bottom: 10px;
    padding: 10px 24px;
    font-size: 0.8rem;
    color: $midGrey;
    @media print {
      position: fixed;
    }
    @media screen {
      position: relative;
    }
  }

  .group-title {
    .branding {
      height: 140px;

      img {
        height: 70px;
      }
    }
  } // end group-title

  .box-comment {
    padding: 10px 16px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    p {
      font-size: 0.9rem;
    }
    p:last-child {
      margin-bottom: 0;
    }
    h3 {
      font-size: 1.2rem;
    }
  }

  .title-band {
    background-color: $darkSea;
    color: $white;
    height: 500px;
    position: relative;
  }

  .title-band--summary {
    left: 50px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .title-copyright {
    background-color: #a5c48f;
    margin-top: 5px;
  }

  .table-tm-dark-striped {
    font-size: 0.95rem;
    $border: solid 1px rgba(0, 0, 0, 0.1);
    border-left: $border;
    border-bottom: $border;
    thead {
      tr {
        th {
          padding: 20px 16px;
          -webkit-print-color-adjust: exact;
          background: rgba($darkSea, 0.3) !important;
          vertical-align: middle;
          text-align: center;
          border-right: $border;
        }
      }
    }
    tr {
      td {
        vertical-align: middle;
        text-align: center;
        border-right: $border;
        padding: 12px 16px;
      }
      &:nth-child(odd) {
        td {
          -webkit-print-color-adjust: exact;
          background-color: rgba($darkSea, 0.12) !important;
        }
      }
    }
  } // end tm report table

  .title-date {
    background-color: #3e7985;
    margin-bottom: 5px;
  }

  .title-box {
    color: $white;
    height: 80px;
    position: relative;
    padding: 10px;
    padding-left: 25px;

    .vertical-center {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .title-col {
    padding-right: 5px !important;
  }

  .title-image {
    background-size: cover;
    height: 400px;
  }

  .title-photos {
    margin-top: 5px;

    > div {
      padding: 0;
    }

    img {
      max-width: 100%;
    }
  }

  .block {
    h3 {
      font-size: 0.9rem;
      line-height: 0.9rem;
      font-weight: 700;
      border-bottom: solid 1px #fff;
      margin-bottom: 16px;
      padding-bottom: 10px;
      display: inline-block;
      text-transform: uppercase;
    }

    p {
      font-size: 0.7rem;
      line-height: 0.9rem;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 6px;
      }
    }
  } // end block

  .page {
    max-width: 1280px;
    margin: 0px auto;
    padding: 0 0 0 0;
    min-height: 100vh;

    // Add a Border to help break up the pages...
    border-top: solid 1px rgba(0, 0, 0, 0.2);
    &:first-child {
      border-top: none;
    }
    // Hide border on print
    @media print {
      border-top: none;
      page-break-after: always;
    }

    @media screen {
      padding-bottom: 20px;
    }

    &.padding {
      padding: 24px;
    }

    .indicator-suggestions {
      margin: 0px auto;
      padding: 20px 5px 40px;

      small {
        display: block;
        font-size: 15px;
        margin-top: 10px;
      }
    }
  } // end page.

  table.table {
    td.col-red,
    th.col-red {
      border-bottom: solid 1px rgba(0, 0, 0, 0.2) !important;
      -webkit-print-color-adjust: exact;
      background-color: $red !important; // needed for printing
      color: #fff !important; // needed for printing
    }

    td.col-yellow,
    th.col-yellow {
      border-bottom: solid 1px rgba(0, 0, 0, 0.2) !important;
      -webkit-print-color-adjust: exact;
      background-color: $mustard !important; // needed for printing
      color: #fff !important; // needed for printing
    }

    td.col-light-green,
    th.col-light-green {
      border-bottom: solid 1px rgba(0, 0, 0, 0.2) !important;
      -webkit-print-color-adjust: exact;
      background-color: $lightGreen !important; // needed for printing
      color: #fff !important; // needed for printing
    }

    td.col-green,
    th.col-green {
      border-bottom: solid 1px rgba(0, 0, 0, 0.2) !important;
      -webkit-print-color-adjust: exact;
      background-color: $darkGreen !important; // needed for printing
      color: #fff !important; // needed for printing
    }
  }
} // end tm-group report
