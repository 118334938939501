.small-blue-button {
  background-color: $lightBlue !important;
  height: 50px;
  font-size: 12pt;
  width: 10rem;
  color: white;
  -webkit-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
  border-radius: 3rem;
  border: none;
  margin-right: 1rem;

  &:hover {
    background-color: $midBlue !important;
    color: $white;
    transition: background-color 300ms ease;
  }
}

.small-blue-button-outlined {
  background-color: $transparent;
  height: 50px;
  font-size: 12pt;
  min-width: 10rem;
  color: $midBlue;
  transition: background-color 300ms ease;
  border-radius: 3rem;
  border: 0.08rem solid $midBlue !important;
  margin-right: 1rem;

  &:hover {
    background-color: $midBlue;
    color: $white;
    transition: background-color 300ms ease;
  }
}

.small-red-button-outlined {
  background-color: $transparent;
  height: 50px;
  font-size: 12pt;
  width: 10rem;
  color: red;
  transition: background-color 300ms ease;
  border-radius: 3rem;
  border: 0.08rem solid red !important;
  margin-right: 1rem;

  &:hover {
    background-color: red;
    color: $white;
    transition: background-color 300ms ease;
  }
}

.small-gray-button-outlined {
  background-color: $transparent;
  height: 50px;
  font-size: 20pt;
  font-weight: bold;
  width: 9rem;
  color: gray;
  transition: background-color 300ms ease;
  border-radius: 3rem;
  border: 0.08rem solid gray !important;
  margin-right: 1rem;

  &:hover {
    background-color: $transparent;
    color: $midBlue;
    transition: background-color 300ms ease;
    border: 0.08rem solid $midBlue !important;
    cursor: pointer;
  }
}

.button {
  border-radius: 50px;
  font-weight: bold;
  font-size: 13pt;
  cursor: pointer;
  border: none;
  margin-top: 25px;
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
}
.green {
  width: 300px;
  background-color: $darkGreen;
  color: white;
  transition: background-color 300ms ease;
  height: 75px;
  &:hover {
    background-color: $darkerGreen;
    color: white;
  }
  &:hover > div > div > img {
    transform: translateX(10px);
  }
  div {
    div {
      img {
        transition: transform 1s ease;
      }
    }
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.3rem rgba($darkerGreen, 0.25);
  }

  &[disabled] {
    background-color: #56af56;
    &:hover {
      background-color: #56af56;
    }
  }
}

.disabled,
[disabled] {
  opacity: 0.5;
  cursor: no-drop;
  &:hover > div > img {
    transform: none;
  }
}

.small-button {
  height: 50px;
  font-size: 12pt;
  width: auto;
  min-width: 10rem;
  color: white;
  background-color: #56af56;
  transition: background-color 300ms ease;
  border-radius: 3rem;
  border: none;
  margin-right: 1rem;
}

.small-button-toggle {
  height: 50px;
  font-size: 12pt;
  width: 10rem;
  color: white;
  background-color: $midGrey;
  transition: background-color 300ms ease;
  border-radius: 3rem;
  border: none;
  margin-right: 1rem;

  &:hover {
    background-color: #56af56 !important;
  }

  &.active {
    background-color: #56af56 !important;
  }
}

.nav-buttons .col {
  padding-left: 5px;
  padding-right: 5px;

  &:first-child {
    padding-left: 15px;
  }

  &:last-child {
    padding-right: 15px;
  }
}

.nav {
  background-color: white;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.1);
  padding: 2rem 16px;
  outline: none;
  min-height: 300px;
  width: 100%;
  transition: all 0.1s ease-in-out;

  &:active {
    box-shadow: none;
  }

  &:hover {
    background-color: $lightBlue;
    color: white;
  }

  &:disabled {
    opacity: 0.3;
    &:hover {
      background-color: white;
      color: rgba(0, 0, 0, 0.6);
    }
    &:active {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    }
  }

  div {
    text-align: center;
    margin: 0 auto;
    justify-content: center;
  }

  .icon {
    width: 30%;
    margin-bottom: 20px;

    svg {
      max-width: 100px;
      max-height: 100px;
    }
  }

  .desc {
    font-size: 20px;
    line-height: 22px;
  }

  .mainText {
    font-size: 22px;
    font-weight: bold;
    line-height: 22px;
    margin-bottom: 4px;
    text-transform: uppercase;
  }
}

.button-text {
  background: transparent;
  border: none;
  color: $midBlue;
  cursor: pointer;

  &.no-link {
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }
}
