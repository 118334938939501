.footnote-text-edit-participant {
  font-size: 0.7rem;
  &:first-of-type {
    margin-top: 1rem;
  }
}

.header-survey-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  &:last-of-type {
    margin-bottom: 1rem;
  }
}
.modal-header-edit-participant {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.student-results-label-edit-participant {
  font-size: 0.9rem;
}

.survey-info{
  div {
    &:first-child {
      margin-right: 10rem;
    }
  }
}

.context-menu {
  margin-top: 14px;
  display: inline-flex;
  line-height: 5px;
  border: 1px solid black;
  border-radius: 3rem;
  width: 3rem;
  height: 20px;
  text-align: center;
  padding-left: 11px;
}

.dropdown {
  position: relative;
  float: right;
}

.dropdown-content {
  display: none;
  position: absolute;
  // margin-top: 5px;
  background-color: #f1f1f1;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.4);
  z-index: 1;
  right: 0;
  float: right;
}

.dropdown-content span {
  color: $midBlue;
  padding: 8px 5px;
  font-size: 1rem;
  text-decoration: none;
  display: block;
}

.dropdown-content span:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
  float: right;
}

.grade-label {
  color: gray;
  padding: 0;
  font-size: .9rem;
}

@media print {
  header,
  footer,
  .tableHeader,
  .create-survey-container .table td:nth-child(n+5) {
    display: none !important;
  }

  .create-survey-container .table td {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .create-survey-container .table td:nth-child(2):before {
    content: 'Grade:';
    margin-right: 0.5rem;
  }

  .create-survey-container .table td:nth-child(3):before {
    content: 'Date of Birth:';
    margin-right: 0.5rem;
  }

  .create-survey-container .table td:nth-child(4):before {
    content: 'Login:';
    margin-right: 0.5rem;
  }
}

.report-link {
  display: inline-block;

  .report-icon {
    display: block;
    position: relative;

    &:before,
    &:after {
      position: absolute;
      display: block;
    }

    &[data-contacted=true]:before {
      content: '✓';
      top: 8px;
      right: -22px;
      height: 13px;
      width: 17px;
      font-size: 22px;
      font-weight: 700;
      line-height: 0.65;
      color: $darkGreen;
    }

    &[data-num]:after {
      content: attr(data-num);
      border: 4px solid white;
      border-radius: 50%;
      right: -9px;
      bottom: -4px;
      z-index: 1;
      height: 22px;
      width: 22px;
      color: white;
      font-weight: 900;
      font-size: 11px;
      text-align: center;
    }

    &[data-num='1']:after,
    &[data-num='2']:after {
      background-color: $red;
    }

    &[data-num='3']:after,
    &[data-num='4']:after,
    &[data-num='5']:after {
      background-color: $mustard;
    }
  }
}

.statusCountText{
  color: $darkGrey;
  float:right;
  font-size: $font-size-sm;
}

.modal-table-container{
  height:300px;
  overflow-y:scroll;
}