.container-all-questions {
	width: 80%;
	margin: 2rem auto 0;

	.answerContainer {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-top: 30px;
		margin-bottom: 40px;
	}
} // end container-all-questions

.centerText {
	text-align: center !important;
	margin-bottom: 20px;
}

.container-single-question {
	animation: fadeIn 0.3s ease-out 1 forwards;
	opacity: 1;
	margin: 0 auto;
	min-height: 600px;
	min-width: 50vw;
	max-width: 80%;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	left: 0;

	&:focus {
		outline: none;
	}

	.subhead {
		margin-bottom: 5px;
	}
}
.hidden {
	display: none;
}
.head {
	font-weight: bold;
	font-size: 18pt;
	margin-top: 0;
	margin-bottom: 0;
	line-height: 35px;
}
.heading {
	text-align: left;
	width: 100%;
}
.justifyCenter {
  justify-content: center;
  
}

.progressBar {
	margin-bottom: 50px;
  margin-top: 50px;
  
}
.react-sweet-progress-line {
  height:20px;
}

.react-sweet-progress-line-inner {
  height:20px;
}

.react-sweet-progress-symbol {
	white-space: nowrap;
}
